/* ---------
LIBRARIES
---------------*/
// GSAP + ScrollTrigger (https://github.com/greensock/GSAP)
import { gsap } from "gsap";
// import { CustomEase } from 'gsap/CustomEase';
// gsap.registerPlugin(CustomEase);
// import { random } from 'gsap/gsap-core';

// Create custom eases
// CustomEase.create('expoIn', '1,.08,.88,.22');
// CustomEase.create('expoOut', '.08,.82,.16,.98');

// CustomEase.create('expoIn', '.79,.12,.88,.14');
// CustomEase.create('expoOut', '.09,1.1,.22,.9');

// Swup + Debug Plugin (https://github.com/swup/swup) + GTAG Plugin (https://github.com/joshuaHallee/swup-gtag-plugin)
import Swup from "swup";
// import SwupDebugPlugin from "@swup/debug-plugin";
import SwupJsPlugin from "@swup/js-plugin";

// Bowser (https://github.com/lancedikson/bowser)
// import Bowser, { parse } from "bowser";
import Bowser from "bowser";

// Data
import data from "./data.js";

// Audio
import Plyr from "plyr";

// Glightbox [Lightbox]
import GLightbox from "glightbox";
import glightboxStyles from "glightbox/dist/css/glightbox.min.css";

/* ---------
LOAD IMAGES & ROULETTE
---------------*/
// Check cache function
function is_cached(src) {
  var image = new Image();
  image.src = src;
  return image.complete;
}

// LOAD IMAGES BEHIND THE SCENES
function loadImages(loading) {
  // Set formats
  var format = "jpg";
  var folder = "mozjpeg";
  if (Modernizr.webp) {
    format = "webp";
    folder = "webp";
  }

  // Check images cache
  var imagesLoadedLocal = localStorage.getItem("imagesLoaded");
  if (imagesLoadedLocal) {
    var checkCache = is_cached(`${theme_directory}/photos/${folder}/${imagesLoadedLocal - 1}.${format}`);
    if (!checkCache) {
      localStorage.removeItem("imagesLoaded");
      imagesLoadedLocal = false;
    }
  }

  // Get real count of images after checking
  var countImages = imagesLoadedLocal ? parseInt(imagesLoadedLocal) : 61;

  if (loading == "active") {
    let roulette = document.querySelector("#roulette");

    if (imagesLoadedLocal) {
      for (
        var countImageLoadedLocal = 61;
        countImageLoadedLocal < parseInt(imagesLoadedLocal);
        countImageLoadedLocal++
      ) {
        var newImage = new Image();
        newImage.src = `${theme_directory}/photos/${folder}/${countImageLoadedLocal}.${format}`;
        newImage.alt = `Ejercicio N°${countImageLoadedLocal}`;
        newImage.setAttribute("data-image", countImageLoadedLocal);
        newImage.classList.add("roulette-image", "roulette-image-loaded");
        roulette.appendChild(newImage);
      }
    }

    let loadImage = function () {
      var countImagesInt = countImages;
      var newImage = new Image();
      newImage.src = `${theme_directory}/photos/${folder}/${countImagesInt}.${format}`;
      newImage.onload = function () {
        if (newImage.complete) {
          newImage.alt = `Ejercicio N°${countImagesInt}`;
          newImage.setAttribute("data-image", countImagesInt);
          newImage.classList.add("roulette-image", "roulette-image-loaded");
          roulette.appendChild(newImage);
        }
      };

      if (countImagesInt <= 200) {
        // 148
        localStorage.setItem("imagesLoaded", countImagesInt);
      }

      countImages++;

      if (countImagesInt >= 200) {
        // 147
        clearInterval(timingImages);
      }
    };
    let timingImages = window.setInterval(loadImage, 1000);
  }

  if (loading == "pasive") {
    let loadImage = function () {
      var countImagesInt = countImages;
      var newImage = new Image();
      newImage.src = `${theme_directory}/photos/${folder}/${countImagesInt}.${format}`;

      if (countImagesInt <= 200) {
        // 148
        localStorage.setItem("imagesLoaded", countImagesInt);
      }

      countImages++;

      if (countImagesInt >= 200) {
        // 147
        clearInterval(timingImages);
      }
    };
    let timingImages = window.setInterval(loadImage, 1000);
  }
}

// ROULETTE IMAGES
function flashImages() {
  var isPause = false;
  var previousRandomNumber = null;
  var format = "jpg";
  var folder = "mozjpeg";
  if (Modernizr.webp) {
    format = "webp";
    folder = "webp";
  }
  let roulette = document.querySelector("#roulette");
  let overlay = document.querySelector("#overlay");

  // Data Image
  let overlayImage = overlay.querySelector("#overlay-image");
  let overlayTitle = overlay.querySelector("#overlay-title");
  let overlayTechnique = overlay.querySelector("#overlay-text-technique");
  let overlayDevice = overlay.querySelector("#overlay-text-device");
  let overlayPlace = overlay.querySelector("#overlay-text-place");
  let overlayTown = overlay.querySelector("#overlay-text-town");
  let overlayRegion = overlay.querySelector("#overlay-text-region");
  let overlayCountry = overlay.querySelector("#overlay-text-country");
  let overlayMap = overlay.querySelector("#overlay-text-map");
  let overlayDate = overlay.querySelector("#overlay-text-date");
  let ambientNoise = document.querySelector("#ambient-noise");

  // Countdown
  let audioCountdown = document.querySelector("#audio-countdown");
  let timer = document.querySelector("#timer");
  var countdownInterval; // Countdown interval
  var countdownNumber = 30;

  // Flash function
  let flash = function () {
    if (!isPause) {
      var rouletteImages = roulette.querySelectorAll("img");
      var quantityImages = rouletteImages.length;
      var randomNumber = Math.floor(Math.random() * quantityImages);

      if (randomNumber === previousRandomNumber) {
        if (randomNumber === quantityImages - 1) {
          randomNumber -= 1;
        } else {
          randomNumber += 1;
        }
      }
      rouletteImages.forEach((image, index) => {
        if (index === randomNumber) {
          image.style.opacity = "1";
          image.classList.add("roulette-image-active");
        } else {
          image.style.opacity = "0";
          image.classList.remove("roulette-image-active");
        }
      });

      previousRandomNumber = randomNumber;
    }
  };
  let flashingImages = window.setInterval(flash, 70); // Use 70 - 500 is for testing purposes

  // Pause Roulette -> Overlay appears
  roulette.addEventListener("click", () => {
    isPause = !isPause;

    if (isPause) {
      // Window size
      var windowHeight = window.innerHeight;
      var calculateHeightWindow = windowHeight * 0.9;

      overlay.classList.add("overlay-active");
      ambientNoise.classList.remove("inactive");

      // Bring image selected
      var activeImage = roulette.querySelector(".roulette-image-active");
      var imageNumber = activeImage.dataset.image;
      var newImageActive = new Image();
      newImageActive.src = `${theme_directory}/photos/${folder}/${imageNumber}.${format}`;
      newImageActive.alt = `Ejercicio N°${imageNumber}`;
      overlayImage.appendChild(newImageActive);
      newImageActive.onload = function () {
        newImageActive.classList.add("roulette-overlay-image-loaded");

        // Calculate max width of container according to 95% of screen height (proportional width)
        var imageWidth = newImageActive.naturalWidth;
        var imageHeight = newImageActive.naturalHeight;
        var proportionHeight = calculateHeightWindow / imageHeight;
        var maxWidthSizeImage = imageWidth * proportionHeight;

        newImageActive.parentElement.style.maxWidth = `${maxWidthSizeImage}px`;
      };

      // Add text
      var imageNumberArray = imageNumber - 1;
      // overlayTitle.innerText = activeImage.alt;
      overlayTitle.innerText = data["data-epefdm"][imageNumberArray]["Nombre"];
      overlayTechnique.innerHTML = data["data-epefdm"][imageNumberArray]["Técnica"];
      overlayDevice.innerText = data["data-epefdm"][imageNumberArray]["Dispositivo"];
      overlayPlace.innerText = data["data-epefdm"][imageNumberArray]["Barrio/Pueblo/Villa"];
      overlayTown.innerText = data["data-epefdm"][imageNumberArray]["Comuna"];
      overlayRegion.innerText = data["data-epefdm"][imageNumberArray]["Región"];
      overlayCountry.innerText = data["data-epefdm"][imageNumberArray]["País"];
      overlayDate.innerText = data["data-epefdm"][imageNumberArray]["Fecha"];
      overlayMap.innerText = data["data-epefdm"][imageNumberArray]["Coordenadas"];
      overlayMap.href = data["data-epefdm"][imageNumberArray]["Link Streetview"];

      // Transition
      var loadTimer = window.setInterval(function () {
        if (newImageActive.classList.contains("roulette-overlay-image-loaded")) {
          // gsap.to(newImageActive, {clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', webkitClipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', duration: 0.4, ease: 'expoIn'});
          gsap.to(overlayImage, { opacity: 1, duration: 0.1, ease: "none", delay: 0.3 });
          // gsap.to(infoButton, {opacity: 1, duration: 0.1, ease: 'none', delay: 0.3});
          // infoButton.classList.add('info-active');
          timer.classList.add("timer-active");

          clearInterval(loadTimer);
        }
      }, 200);

      // Timer to close overlay
      let countdownTimer = function () {
        if (countdownNumber == 11) {
          audioCountdown.play();
        }
        if (countdownNumber <= 10) {
          timer.textContent = countdownNumber;
        }
        if (countdownNumber < 1) {
          overlay.click();
        } else {
          countdownNumber--;
        }
      };
      countdownInterval = window.setInterval(countdownTimer, 1000);
    }
  });

  // Hide overlay -> Play roulette
  overlay.addEventListener("click", () => {
    if (isPause) {
      // Play roulette
      isPause = !isPause;

      // Clear countdown timer
      clearInterval(countdownInterval);
      countdownNumber = 30;
      timer.textContent = "";

      // Get current image
      var currentImage = overlayImage.querySelector("img.roulette-overlay-image-loaded");

      // Transition
      let transitionOut = gsap.timeline({
        default: { repeat: 0 },
        onComplete: function () {
          overlay.classList.remove("overlay-active");
          timer.classList.remove("timer-active");
          currentImage.remove();
          ambientNoise.classList.add("inactive");
        },
      });
      // transitionOut.to(overlayTitle, {opacity: 0, duration: 0.1, ease: 'none'})
      transitionOut.to(overlayImage, { opacity: 0, duration: 0.1, ease: "none" });
      // transitionOut.to(infoButton, {opacity: 0, duration: 0.1, ease: 'none'})
    }
  });
}

// ROULETTE IMAGES 18
function roulette18() {
  let roulette18 = document.querySelector("#roulette-18");
  let roulette18Images = roulette18.querySelectorAll(".roulette-18-image");
  let countImages = 2;

  roulette18.addEventListener("click", () => {
    roulette18Images.forEach((image) => {
      var dataImage = image.dataset.image;
      if (dataImage == countImages) {
        image.style.display = "block";
      } else {
        image.style.display = "none";
      }

      if (dataImage == 1) {
        countImages++;
      }

      if (countImages > 21) {
        countImages = 1;
      }
    });
  });
}

/* ---------
18 VERIFICATION
---------------*/
// function is18() {
// 	var web18 = localStorage.getItem('web18');
// 	var button = document.querySelector('#loading-enter');

// 	if (web18 == 'Ok') {
// 		button.textContent = 'Entrar';
// 		button.nextElementSibling.style.display = 'none';
// 	}
// }

/* ---------
PROJECT
---------------*/
function project(container) {
  // Time & Date
  let time = document.querySelector("#current-time");
  let date = document.querySelector("#current-date");

  let setTime = () => {
    var currentTimeChile = new Date().toLocaleTimeString("es", { timeZone: "America/Santiago" });
    time.textContent = currentTimeChile;
  };
  setInterval(setTime, 1000);

  var currentDateChile = new Date().toLocaleDateString("es", { timeZone: "America/Santiago" });
  date.textContent = currentDateChile;
}

/* ---------
TEXTS
---------------*/
function texts() {
  let linesText = document.querySelectorAll(".text-line");
  let imagesText = document.querySelectorAll(".text-image");
  let textContainer = document.querySelector("#texts-container");

  let textInteractionOver = function () {
    this.classList.add("active");
    linesText.forEach((line) => {
      if (!line.classList.contains("active")) {
        line.classList.add("inactive");
      }
    });
    let image = Array.from(imagesText).find((number) => number.dataset.image == this.dataset.text);
    image.classList.add("active");
    textContainer.classList.add("active");
  };

  let textInteractionOut = function () {
    this.classList.remove("active");
    linesText.forEach((line) => {
      line.classList.remove("inactive");
    });
    let image = Array.from(imagesText).find((number) => number.dataset.image == this.dataset.text);
    image.classList.remove("active");
    textContainer.classList.remove("active");
  };

  linesText.forEach((line) => {
    line.addEventListener("mouseover", textInteractionOver);
    line.addEventListener("mouseout", textInteractionOut);
  });
}

/* ---------
AUDIO/MUSIC
---------------*/
const playerMusicBackground = new Plyr("#music-player");
var musicPlayed = false;
var randomPlaylist = Math.floor(Math.random() * (60 - 1 + 1)) + 1; // Inclusive minimum and maximum (max - min + 1) + min)

// Set first source
playerMusicBackground.source = {
  type: "audio",
  sources: [
    {
      src: `${theme_directory}/music/audio-${randomPlaylist}.mp3`,
      type: "audio/mp3",
    },
  ],
};

// Next Song
let nextSong = () => {
  var randomPlaylist = Math.floor(Math.random() * (60 - 1 + 1)) + 1; // Inclusive minimum and maximum (max - min + 1) + min)
  playerMusicBackground.source = {
    type: "audio",
    sources: [
      {
        src: `${theme_directory}/music/audio-${randomPlaylist}.mp3`,
        type: "audio/mp3",
      },
    ],
  };
  playerMusicBackground.play();
};
playerMusicBackground.on("ended", nextSong);

/*-------------------------
ONLOAD FUNCTIONS
----------------------------------*/
const ready = function () {
  // IS 18
  // is18();
};

const load = function () {
  let swupContainer = document.querySelector("#swup");
  let overlayLoading = document.querySelector("#overlay-loading");
  let loadingText = document.querySelector("#loading-text");
  let loadingEnter = document.querySelector("#loading-enter");
  let loadingEnterContainer = document.querySelector(".loading-enter-container");
  let loadingEnterButtons = loadingEnterContainer.querySelectorAll(".button");
  let start = document.querySelector("#loading-enter");
  let homeLink = document.querySelector("#loading-home");
  let ambientNoise = document.querySelector("#ambient-noise");

  // Roulette
  if (swupContainer.classList.contains("home")) {
    loadImages("active");
    flashImages();
  } else {
    loadImages("pasive");
  }

  // Roulette -18
  if (swupContainer.classList.contains("x-18")) {
    roulette18();
    ambientNoise.classList.add("inactive");
  }

  // Overloading
  if (swupContainer.classList.contains("home")) {
    loadingEnterButtons.forEach((button) => {
      button.addEventListener("mouseover", () => {
        loadingEnterButtons.forEach((button) => {
          button.classList.add("active");
        });
      });
      button.addEventListener("mouseout", () => {
        loadingEnterButtons.forEach((button) => {
          button.classList.remove("active");
        });
      });
    });

    // Fade out loading
    setTimeout(() => {
      loadingText.style.display = "none";
      loadingEnterContainer.style.display = "block";

      loadingEnter.addEventListener("click", () => {
        overlayLoading.classList.add("fadeOut");
        localStorage.setItem("web18", "Ok");
        setTimeout(() => {
          overlayLoading.style.display = "none";
          ambientNoise.classList.add("inactive");
        }, 601);
      });
    }, 4000);
  } else {
    overlayLoading.classList.add("fadeOut");
    setTimeout(() => {
      overlayLoading.style.display = "none";
    }, 601);
  }

  // Project Page
  if (swupContainer.classList.contains("proyecto")) {
    project(swupContainer);

    ambientNoise.classList.remove("inactive");

    homeLink.addEventListener("click", () => {
      playerMusicBackground.play();
      musicPlayed = true;
    });

    texts();

    // Video Lightbox
    glightboxStyles.use();
    const videoProject = GLightbox({
      selector: ".vimeo-lightbox",
      touchNavigation: false,
      autoplayVideos: true,
      draggable: false,
    });

    videoProject.on("slide_changed", ({ prev, current }) => {
      const { slideIndex, slideNode, slideConfig, player, trigger } = current;
      if (player) {
        player.on("ended", (event) => {
          videoProject.close();
        });
      }
    });
  }

  // Player Music Background
  if (swupContainer.classList.contains("home")) {
    start.addEventListener("click", () => {
      playerMusicBackground.play();
      musicPlayed = true;
    });

    if (musicPlayed) {
      playerMusicBackground.play();
    }
  } else {
    playerMusicBackground.pause();
  }
};

document.addEventListener("DOMContentLoaded", ready);
window.addEventListener("load", load);

/*-------------------------
POLYFILLS
----------------------------------*/
const info = Bowser.parse(window.navigator.userAgent);
const osDetect = info.os.name;

if (osDetect == "iOS") {
  document.documentElement.className += " ios-detected";
} else {
  //
}

/*-------------------------
SWUP
----------------------------------*/
// Transitions
const options = [
  {
    // Default Animation
    from: "(.*)",
    to: "(.*)",
    out: (next) => {
      gsap.fromTo("#swup", { opacity: 1 }, { opacity: 0, duration: 0.4, ease: "none", onComplete: next });
    },
    in: (next) => {
      gsap.fromTo("#swup", { opacity: 0 }, { opacity: 1, duration: 0.4, ease: "none", onComplete: next });
    },
  },
  {
    // Default Animation
    from: "(.*)",
    to: "/",
    out: (next) => {
      gsap.fromTo("#swup", { opacity: 1 }, { opacity: 0, duration: 0.4, ease: "none", onComplete: next });
    },
    in: (next) => {
      gsap.fromTo("#swup", { opacity: 0 }, { opacity: 1, duration: 0.4, ease: "none", onComplete: next });
      let ambientNoise = document.querySelector("#ambient-noise");
      ambientNoise.classList.add("inactive");
    },
  },
  {
    // Default Animation
    from: "/",
    to: "/x-18",
    out: (next) => {
      gsap.fromTo("#swup", { opacity: 1 }, { opacity: 0, duration: 0.4, ease: "none", onComplete: next });
    },
    in: (next) => {
      var overlayLoading = document.querySelector("#overlay-loading");
      gsap.fromTo(
        "#swup",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.4,
          ease: "none",
          onComplete: function () {
            next();
            overlayLoading.classList.add("fadeOut");
            setTimeout(() => {
              overlayLoading.style.display = "none";
            }, 601);
          },
        }
      );
    },
  },
];

const swup = new Swup({
  plugins: [
    // new SwupDebugPlugin(),
    new SwupJsPlugin(options),
  ],
});

// LOAD ALL BASE SCRIPTS
swup.on("pageView", ready);
swup.on("pageView", load);

// HANDLING SCROLL ON PAGES
swup.on("clickLink", function (e) {
  function scrollPage() {
    window.scroll(0, 0);
    swup.off("pageView", scrollPage);
  }
  swup.on("pageView", scrollPage);
});

// POPSTATE
swup.on("popState", function (e) {
  var overlayLoading = document.querySelector("#overlay-loading");
  var loadingText = document.querySelector("#loading-text");
  var loadingEnter = document.querySelector("#loading-enter");
  var loadingEnterContainer = document.querySelector(".loading-enter-container");
  var loadingEnterButtons = loadingEnterContainer.querySelector(".button");
  var ambientNoise = document.querySelector("#ambient-noise");

  function transitionPop() {
    overlayLoading.style.display = "flex";
    overlayLoading.classList.remove("fadeOut");

    loadingText.style.display = "flex";
    loadingEnterContainer.style.display = "none";
    ambientNoise.classList.remove("inactive");

    swup.off("transitionStart", transitionPop);
  }
  swup.on("transitionStart", transitionPop);

  function transitionPage() {
    var swupContainer = document.querySelector("#swup");
    if (swupContainer.classList.contains("home")) {
      loadingEnterButtons.forEach((button) => {
        button.addEventListener("mouseover", () => {
          loadingEnterButtons.forEach((button) => {
            button.classList.add("active");
          });
        });
        button.addEventListener("mouseout", () => {
          loadingEnterButtons.forEach((button) => {
            button.classList.remove("active");
          });
        });
      });

      // Fade out loading
      setTimeout(() => {
        loadingText.style.display = "none";
        loadingEnterContainer.style.display = "block";

        loadingEnter.addEventListener("click", () => {
          overlayLoading.classList.add("fadeOut");
          localStorage.setItem("web18", "Ok");
          setTimeout(() => {
            overlayLoading.style.display = "none";
            ambientNoise.classList.add("inactive");
          }, 601);
        });
      }, 2000);
    } else {
      overlayLoading.classList.add("fadeOut");
      setTimeout(() => {
        overlayLoading.style.display = "none";
        ambientNoise.classList.add("inactive");
      }, 601);
    }

    swup.off("pageView", transitionPage);
  }
  swup.on("pageView", transitionPage);
});

// Images
// squoosh-cli --mozjpeg '{"quality":75,"baseline":false,"arithmetic":false,"progressive":true,"optimize_coding":true,"smoothing":0,"color_space":3,"quant_table":3,"trellis_multipass":false,"trellis_opt_zero":false,"trellis_opt_table":false,"trellis_loops":1,"auto_subsample":true,"chroma_subsample":2,"separate_chroma_quality":false,"chroma_quality":75}' -d mozjpeg 140.jpg 141.jpg 142.jpg 143.jpg 144.jpg 145.jpg 146.jpg 147.jpg

// squoosh-cli --webp '{"quality":75,"target_size":0,"target_PSNR":0,"method":4,"sns_strength":50,"filter_str ength":60,"filter_sharpness":0,"filter_type":1,"partitions":0,"segments":4,"pass":1,"show_compressed":0,"preprocessing":0,"autofilter":0,"partition_limit":0,"alpha_compression":1,"alpha_filtering":1,"alpha_quality":100,"lossless":0,"exact":0,"image_hint":0,"emulate_jpeg_size":0,"thread_level":0,"low_memory":0,"near_lossless":100,"use_delta_palette":0,"use_sharp_yuv":0}' -d webp 1.jpg 2.jpg 3.jpg 4.jpg 5.jpg 6.jpg 7.jpg 8.jpg 9.jpg
