export default {
  "data-epefdm": [
    {
      ID: "1",
      Nombre: "Ejercicio N°01",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "MacBook Air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/TbkVTRLCYszgrtLy9",
    },
    {
      ID: "2",
      Nombre: "Ejercicio N°02",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "MacBook Pro",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Parque de Los Reyes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′42″S 70°40′11″O",
      "Link Streetview": "https://goo.gl/maps/AqGA2Y7a4Kqb8ooX8",
    },
    {
      ID: "3",
      Nombre: "Ejercicio N°03",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "MacBook Pro 10",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Collao, Barrio Norte",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′2″S 73°0′46″W",
      "Link Streetview": "https://goo.gl/maps/hYXrHWqgfCRuMFAo8",
    },
    {
      ID: "4",
      Nombre: "Ejercicio N°04",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagónica",
      País: "Argentina",
      Coordenadas: "36°09′02″S 70°23′47″O",
      "Link Streetview": "https://goo.gl/maps/CM4MKntRE2vbQMjm7",
    },
    {
      ID: "5",
      Nombre: "Ejercicio N°05",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Infinix Smart 4",
      Comuna: "Pereira",
      "Barrio/Pueblo/Villa": "Cañarte",
      Región: "Departamento de Risaralda",
      País: "Colombia",
      Coordenadas: "4°48′51″N 75°41′40″O",
      "Link Streetview": "https://goo.gl/maps/VrmR9mXCPJf58pzx5",
    },
    {
      ID: "6",
      Nombre: "Ejercicio N°06",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Barrio Pinamar, Playa el Faro",
      Región: "Región de Coquimbo, Norte Chico",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/ids6XVpCbngMLexj9",
    },
    {
      ID: "7",
      Nombre: "Ejercicio N°07",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 250 G7",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Hualpén",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°47′00″S 73°05′00″O",
      "Link Streetview": "https://goo.gl/maps/zyZ8EunpLYn57UdT9",
    },
    {
      ID: "8",
      Nombre: "Ejercicio N°08",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Coquimbo",
      "Barrio/Pueblo/Villa": "Tongoy",
      Región: "Región de coquimbo",
      País: "Chile",
      Coordenadas: "30°15′27″S 71°29′33″O",
      "Link Streetview": "https://goo.gl/maps/rcG2dGGwoZCF5JyJA",
    },
    {
      ID: "9",
      Nombre: "Ejercicio N°09",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung T290",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/dVdFtvUUNhB98MAW9",
    },
    {
      ID: "10",
      Nombre: "Ejercicio N°10",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Barrio Pinamar, Playa el Faro",
      Región: "Región de Coquimbo, Norte Chico",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/NmAj5aTf5XTsHH9H7",
    },
    {
      ID: "11",
      Nombre: "Ejercicio N°11 ",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Barrio Pinamar, Playa el Faro",
      Región: "Región de Coquimbo, Norte Chico",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/R57ZPnVN7bVmpCgJ8",
    },
    {
      ID: "12",
      Nombre: "Ejercicio N°12",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung T290",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/D9qVxriXBTR6vGMY9",
    },
    {
      ID: "13",
      Nombre: "Ejercicio N°13",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo yoga 520",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Villa Los Héroes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°32′7.85″S 70°46′45.69″O",
      "Link Streetview": "https://goo.gl/maps/kVs5w2x13w9pkG978",
    },
    {
      ID: "14",
      Nombre: "Ejercicio N°14",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X412FA-EK292T",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Minuto de Dios",
      Región: "Localidad de Engativá",
      País: "Colombia",
      Coordenadas: "4°36′46″N 74°04′14″O",
      "Link Streetview": "https://goo.gl/maps/gRV24Ayeom2gSkN78",
    },
    {
      ID: "15",
      Nombre: "Ejercicio N°15",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "OWN fun 4",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "El Totoral",
      Región: "Región de Valparaíso",
      País: "Chile",
      Coordenadas: "33°25′08″S 71°37′30″O",
      "Link Streetview": "https://goo.gl/maps/5R1N8JKVvuRSwu4e6",
    },
    {
      ID: "16",
      Nombre: "Ejercicio N°16",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iMac 10",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Villa La Pradera",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°32′7.85″S 70°46′45.69″O",
      "Link Streetview": "https://goo.gl/maps/emxoRSVZVHizV5y58",
    },
    {
      ID: "17",
      Nombre: "Ejercicio N°17",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Bellas Artes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′12″S 70°38′40″O",
      "Link Streetview": "https://goo.gl/maps/MAWYtRWL4pcazN4B8",
    },
    {
      ID: "18",
      Nombre: "Ejercicio N°18",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 14",
      Comuna: "Sorata",
      "Barrio/Pueblo/Villa": "Nevado Illampu",
      Región: "Provincia de Larecaja",
      País: "Bolivia",
      Coordenadas: "15°46′25″S 68°38′55″O",
      "Link Streetview": "https://goo.gl/maps/irnToJ7mAQvCQ4ft5",
    },
    {
      ID: "19",
      Nombre: "Ejercicio N°19",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Bellas Artes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′12″S 70°38′40″O",
      "Link Streetview": "https://goo.gl/maps/P66HGHMKEovpUCSa7",
    },
    {
      ID: "20",
      Nombre: "Ejercicio N°20",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagónica ",
      País: "Argentina",
      Coordenadas: "36°09′02″S 70°23′47″O",
      "Link Streetview": "https://goo.gl/maps/8y7YqSjhHpahsWnT9",
    },
    {
      ID: "21",
      Nombre: "Ejercicio N°21",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy NOTE20 ULTRA",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Tres Poniente",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°32′08″S 70°46′09″O",
      "Link Streetview": "https://goo.gl/maps/znwQfX89WQ9Z67t16",
    },
    {
      ID: "22",
      Nombre: "Ejercicio N°22",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Bellas Artes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′12″S 70°38′40″O",
      "Link Streetview": "https://goo.gl/maps/pMmbwAzV9BSQvxtGA",
    },
    {
      ID: "23",
      Nombre: "Ejercicio N°23",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Estación Central",
      "Barrio/Pueblo/Villa": "Las Rejas",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′26″S 70°42′20″O",
      "Link Streetview": "https://goo.gl/maps/mQHCKkPS4MPqTRFC9",
    },
    {
      ID: "24",
      Nombre: "Ejercicio N°24",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola E6 Plus",
      Comuna: "Macul",
      "Barrio/Pueblo/Villa": "Macul con Grecia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°30′00″S 70°34′00″O",
      "Link Streetview": "https://goo.gl/maps/Rfihc5dRXjQ3xCaw9",
    },
    {
      ID: "25",
      Nombre: "Ejercicio N°25",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Yungay",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "3°26′15″S 70°40′25″O",
      "Link Streetview": "https://goo.gl/maps/MLWx5BXE1Sx4oF3T8",
    },
    {
      ID: "26",
      Nombre: "Ejercicio N°26",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook Pro 2011",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Pedro de Valdivia",
      Región: "Región Metropolitana",
      País: "Chile",
      Coordenadas: "33°28′00″S 70°36′00″O",
      "Link Streetview": "https://goo.gl/maps/LBhLsiLV63is9Hzv6",
    },
    {
      ID: "27",
      Nombre: "Ejercicio N°27",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Cerro La Virgen",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′46″S 73°02′03″O",
      "Link Streetview": "https://goo.gl/maps/8JexAWm3FMo3EedF6",
    },
    {
      ID: "28",
      Nombre: "Ejercicio N°28",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo IDEAPAD S145",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Isla negra",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°26′28″S 71°40′58″O ",
      "Link Streetview": "https://goo.gl/maps/x37kEbbJe7A6YHxMA",
    },
    {
      ID: "29",
      Nombre: "Ejercicio N°29",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/zMYnCaaoBmNJPeph9",
    },
    {
      ID: "30",
      Nombre: "Ejercicio N°30",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/WTsEWRWCKDcByvvV6",
    },
    {
      ID: "31",
      Nombre: "Ejercicio N°31",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook Pro",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Parque de Los Reyes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′42″S 70°40′11″O",
      "Link Streetview": "https://goo.gl/maps/asXdxEPfXpWSktEj9",
    },
    {
      ID: "32",
      Nombre: "Ejercicio N°32",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/eRe23csePGETHQcu6",
    },
    {
      ID: "33",
      Nombre: "Ejercicio N°33",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook Pro",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Parque de Los Reyes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′42″S 70°40′11″O ",
      "Link Streetview": "https://goo.gl/maps/z9Q8hubaKk5ZaFxaA",
    },
    {
      ID: "34",
      Nombre: "Ejercicio N°34",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola E6 Plus",
      Comuna: "Macul",
      "Barrio/Pueblo/Villa": "Macul con Grecia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°30′00″S 70°34′00″O",
      "Link Streetview": "https://goo.gl/maps/rveyKppsiqo7WF1R7",
    },
    {
      ID: "35",
      Nombre: "Ejercicio N°35",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 11",
      Comuna: "Chillán",
      "Barrio/Pueblo/Villa": "Villa Cuarto Centenario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°36′24″S 72°06′12″O",
      "Link Streetview": "https://goo.gl/maps/cUzB8u3pu9S7B1zMA",
    },
    {
      ID: "36",
      Nombre: "Ejercicio N°36",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/4hJsSKGnvFj5zHsh9",
    },
    {
      ID: "37",
      Nombre: "Ejercicio N°37",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung T290",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/hTmcPUgdSH1XGwie8",
    },
    {
      ID: "38",
      Nombre: "Ejercicio N°38",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X412FA-EK292T",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Minuto de Dios",
      Región: "Localidad de Engativá",
      País: "Colombia",
      Coordenadas: "4°36′46″N 74°04′14″O",
      "Link Streetview": "https://goo.gl/maps/7TUwpyQKd5MuH5tf7",
    },
    {
      ID: "39",
      Nombre: "Ejercicio N°39",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/SiB7CwT4umhBJmqd6",
    },
    {
      ID: "40",
      Nombre: "Ejercicio N°40",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook Pro",
      Comuna: "Santiago Centro, Vicuña Mackenna",
      "Barrio/Pueblo/Villa": "Plaza Dignidad ",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′13″S 70°38′04″O",
      "Link Streetview": "https://goo.gl/maps/gjKA965aeUBcBek16",
    },
    {
      ID: "41",
      Nombre: "Ejercicio N°41",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Infinix Smart 4",
      Comuna: "Pereira",
      "Barrio/Pueblo/Villa": "Cañarte",
      Región: "Departamento de Risaralda",
      País: "Colombia",
      Coordenadas: "4°48′51″N 75°41′40″O",
      "Link Streetview": "https://goo.gl/maps/5ZkeT9Pgt42ZwVLi7",
    },
    {
      ID: "42",
      Nombre: "Ejercicio N°42",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8 plus",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/xAoHQkzZAudqjR1z8",
    },
    {
      ID: "43",
      Nombre: "Ejercicio N°43",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone XR",
      Comuna: "CDMX Ciudad de México",
      "Barrio/Pueblo/Villa": "Valle de México",
      Región: "Colonia de Aragón",
      País: "México",
      Coordenadas: "19°25′10″N 99°08′44″O",
      "Link Streetview": "https://goo.gl/maps/8p2MC2MpA9wvsmEQA",
    },
    {
      ID: "44",
      Nombre: "Ejercicio N°44",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/qDSeDta6bwKb7RFn7",
    },
    {
      ID: "45",
      Nombre: "Ejercicio N°45",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Metro Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/MfRYtD7J5pc34bDQ7",
    },
    {
      ID: "46",
      Nombre: "Ejercicio N°46",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Cerro La Virgen",
      Región: "Región de Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′46″S 73°02′03″O",
      "Link Streetview": "https://goo.gl/maps/UWgoqmAvHBBxjJoLA",
    },
    {
      ID: "47",
      Nombre: "Ejercicio N°47",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 7",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "El Retiro",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/TpXhaFdqfewhFTGC6",
    },
    {
      ID: "48",
      Nombre: "Ejercicio N°48",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/K7zrMFKVq6HrCsZEA",
    },
    {
      ID: "49",
      Nombre: "Ejercicio N°49",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook pro ",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Estadio Nacional",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′52″S 70°36′38″O",
      "Link Streetview": "https://goo.gl/maps/DmQ66K2t4P1Lai6J7",
    },
    {
      ID: "50",
      Nombre: "Ejercicio N°50",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/wH97fczWo5xQ8hog9",
    },
    {
      ID: "51",
      Nombre: "Ejercicio N°51",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/uBR3DM1V4NM8JceC6",
    },
    {
      ID: "52",
      Nombre: "Ejercicio N°52",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/WcnrtnjpTmnBZBuC7",
    },
    {
      ID: "53",
      Nombre: "Ejercicio N°53",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 14-CK2091LA",
      Comuna: "Melipilla",
      "Barrio/Pueblo/Villa": "Pomaire",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°39′00″S 71°10′00″O",
      "Link Streetview": "https://goo.gl/maps/aTk6U33T75971Jx4A",
    },
    {
      ID: "54",
      Nombre: "Ejercicio N°54",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony VAIO",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Villa Frei",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′52″S 70°36′38″O",
      "Link Streetview": "https://goo.gl/maps/qvqgq49TZhZMpaVg9",
    },
    {
      ID: "55",
      Nombre: "Ejercicio N°55",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/YEwG41XSHkNsD8Ae6",
    },
    {
      ID: "56",
      Nombre: "Ejercicio N°56",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Metro Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/t5ZYPUXkDnf5o6XZ6",
    },
    {
      ID: "57",
      Nombre: "Ejercicio N°57",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Coquimbo, Norte Chico",
      "Barrio/Pueblo/Villa": "Playa El Faro",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "29°57′12″S 71°20′17″O",
      "Link Streetview": "https://goo.gl/maps/QnwB52mHNiFREFwf6",
    },
    {
      ID: "58",
      Nombre: "Ejercicio N°58",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/2DA4MXSEXktrCPze7",
    },
    {
      ID: "59",
      Nombre: "Ejercicio N°59",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "XIAOMI Redmi 9",
      Comuna: "Dalcahue",
      "Barrio/Pueblo/Villa": "Isla Grande del Archipielago de Chiloé",
      Región: "Región de los Lagos",
      País: "Chile",
      Coordenadas: "42°22′47″S 73°38′50″O",
      "Link Streetview": "https://goo.gl/maps/eR4hPNzsnDjrwZQS6",
    },
    {
      ID: "60",
      Nombre: "Ejercicio N°60",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "SAMSUNG Galaxy S21",
      Comuna: "Cádiz",
      "Barrio/Pueblo/Villa": "Chiclana de la Frontera",
      Región: "Comunidad Autónoma de Andalucía",
      País: "España",
      Coordenadas: "36°32′06″N 6°17′51″O ",
      "Link Streetview": "https://goo.gl/maps/W8G3V6EiRmLWvnHc9",
    },
    {
      ID: "61",
      Nombre: "Ejercicio N°61",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook pro",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Estadio Nacional",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′52″S 70°36′38″O",
      "Link Streetview": "https://goo.gl/maps/uxQy6qxkr2WAXGHH7",
    },
    {
      ID: "62",
      Nombre: "Ejercicio N°62",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook pro",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Pedro de Valdivia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′52″S 70°36′38″O",
      "Link Streetview": "https://goo.gl/maps/Akwq4cSL12aXVyYX7",
    },
    {
      ID: "63",
      Nombre: "Ejercicio N°63",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Tongoy",
      "Barrio/Pueblo/Villa": "Guanaqueros",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "30°15′27″S 71°29′33″O",
      "Link Streetview": "https://goo.gl/maps/p66NCyVTtR1Bpmog6",
    },
    {
      ID: "64",
      Nombre: "Ejercicio N°64",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung T290",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/tfRWKHYsWDuPW8mV7",
    },
    {
      ID: "65",
      Nombre: "Ejercicio N°65",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola E6 Plus",
      Comuna: "Macul",
      "Barrio/Pueblo/Villa": "Macul con Grecia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°30′00″S 70°34′00″O",
      "Link Streetview": "https://goo.gl/maps/33jPERYUZd1zR5UUA",
    },
    {
      ID: "66",
      Nombre: "Ejercicio N°66",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Estación Central",
      "Barrio/Pueblo/Villa": "Las Rejas",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′26″S 70°42′20″O",
      "Link Streetview": "https://goo.gl/maps/66X5nQPFvXymwjGq6",
    },
    {
      ID: "67",
      Nombre: "Ejercicio N°67",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Coronel",
      "Barrio/Pueblo/Villa": "Lota",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°05′40″S 73°09′23″O",
      "Link Streetview": "https://goo.gl/maps/KpgEjjck4iwM2VLz6",
    },
    {
      ID: "68",
      Nombre: "Ejercicio N°68",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook pro ",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Plaza Dignidad Vicuña Mackenna",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′13″S 70°38′04″O",
      "Link Streetview": "https://goo.gl/maps/gj8vwCqfmoozZQLUA",
    },
    {
      ID: "69",
      Nombre: "Ejercicio N°69",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire E1",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Playa las Conchitas",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°26′28″S 71°40′58″O ",
      "Link Streetview": "https://goo.gl/maps/yWJGVVztn8vFxbsWA",
    },
    {
      ID: "70",
      Nombre: "Ejercicio N°70",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 5",
      Comuna: "Conchalí",
      "Barrio/Pueblo/Villa": "Metro Conchalí",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°21′00″S 70°37′00″O",
      "Link Streetview": "https://goo.gl/maps/eq2weTUV22qpbGUF7",
    },
    {
      ID: "71",
      Nombre: "Ejercicio N°71",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Metro Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/QJ5Lp2Yxy7H29w7FA",
    },
    {
      ID: "72",
      Nombre: "Ejercicio N°72",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire e15",
      Comuna: "Hualpén",
      "Barrio/Pueblo/Villa": "Cerro Amarillo",
      Región: "Región del Bio Bio",
      País: "Chile",
      Coordenadas: "36°47′00″S 73°05′00″O",
      "Link Streetview": "https://goo.gl/maps/Vu1zmqBcztbG98DT7",
    },
    {
      ID: "73",
      Nombre: "Ejercicio N°73",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola E6 Plus",
      Comuna: "Macul",
      "Barrio/Pueblo/Villa": "Macul con Grecia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°30′00″S 70°34′00″O",
      "Link Streetview": "https://goo.gl/maps/1KJdhErpxadX1XGa6",
    },
    {
      ID: "74",
      Nombre: "Ejercicio N°74",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo 80NJ",
      Comuna: "La Reina",
      "Barrio/Pueblo/Villa": "Parque Peñalolen",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′53.41″S 70°32′49.96″O",
      "Link Streetview": "https://goo.gl/maps/PoFMzBcTg7zzCdSUA",
    },
    {
      ID: "75",
      Nombre: "Ejercicio N°75",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone X",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Parque Ecuador",
      Región: "Región del Bio Bio",
      País: "Chile",
      Coordenadas: "36°49′58″S 73°02′50″O",
      "Link Streetview": "https://goo.gl/maps/xHqeVR3A5Nq6Xmgh6",
    },
    {
      ID: "76",
      Nombre: "Ejercicio N°76",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony VAIO",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Villa Frei",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°28′00″S 70°36′00″O",
      "Link Streetview": "https://goo.gl/maps/RnyiSnYMbDuzEteJ8",
    },
    {
      ID: "77",
      Nombre: "Ejercicio N°77",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "El Retiro",
      Región: "Región del Bio Bio",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/952PCMm7xsqtsUdz6",
    },
    {
      ID: "78",
      Nombre: "Ejercicio N°78",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Coronel",
      "Barrio/Pueblo/Villa": "Lota",
      Región: "Región del Bio Bio",
      País: "Chile",
      Coordenadas: "37°05′40″S 73°09′23″O",
      "Link Streetview": "https://goo.gl/maps/ajEnwr415YkyfqH1A",
    },
    {
      ID: "79",
      Nombre: "Ejercicio N°79",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Estación Central",
      "Barrio/Pueblo/Villa": "Las Rejas ",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′26″S 70°42′20″O",
      "Link Streetview": "https://goo.gl/maps/NjxWcRkktB5BwGwt6",
    },
    {
      ID: "80",
      Nombre: "Ejercicio N°80",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/UePECCPHY7u4kyv89",
    },
    {
      ID: "81",
      Nombre: "Ejercicio N°81",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer aspire e15",
      Comuna: "Hualpén",
      "Barrio/Pueblo/Villa": "Cerro Amarillo",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°47′00″S 73°05′00″O",
      "Link Streetview": "https://goo.gl/maps/amCyf18f7jGsSni68",
    },
    {
      ID: "82",
      Nombre: "Ejercicio N°82",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "SAMSUNG Galaxy S21",
      Comuna: "Cádiz",
      "Barrio/Pueblo/Villa": "Chiclana de la Frontera",
      Región: "Comunidad Autónoma de Andalucía",
      País: "España",
      Coordenadas: "36°32′06″N 6°17′51″O ",
      "Link Streetview": "https://goo.gl/maps/c9bG5QSWBg4wgy3D7",
    },
    {
      ID: "83",
      Nombre: "Ejercicio N°83",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/wzXtMnS3oC97sWp67",
    },
    {
      ID: "84",
      Nombre: "Ejercicio N°84",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Barrio Pinamar, Playa El Faro",
      Región: "Región de Coquimbo, Norte Chico",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/gS19LpMT5wkQVWV49",
    },
    {
      ID: "85",
      Nombre: "Ejercicio N°85",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "HP pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/MATLJvwCL5HXohQd9",
    },
    {
      ID: "86",
      Nombre: "Ejercicio N°86",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/Yspj2FiPdjZ1oiGo9",
    },
    {
      ID: "87",
      Nombre: "Ejercicio N°87",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "HP pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/g46HjUhDBxX8cFDp6",
    },
    {
      ID: "88",
      Nombre: "Ejercicio N°88",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "El Retiro",
      Región: "Region del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/AdM7rxBYhsczj6bb6",
    },
    {
      ID: "89",
      Nombre: "Ejercicio N°89",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/wY33D5Bx15ctWu1o6",
    },
    {
      ID: "90",
      Nombre: "Ejercicio N°90",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagonica",
      País: "Argentina",
      Coordenadas: "41°09′S 71°18′O",
      "Link Streetview": "https://goo.gl/maps/af24zDDTZQiTZtdQ8",
    },
    {
      ID: "91",
      Nombre: "Ejercicio N°91",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Bellas Artes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′12″S 70°38′40″O",
      "Link Streetview": "https://goo.gl/maps/yboLcuVfxPcv7F567",
    },
    {
      ID: "92",
      Nombre: "Ejercicio N°92",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 11",
      Comuna: "Chillan",
      "Barrio/Pueblo/Villa": "Villa Cuarto Centenario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°36′24″S 72°06′12″O",
      "Link Streetview": "https://goo.gl/maps/LB2WevzJEiHFZ65Z7",
    },
    {
      ID: "93",
      Nombre: "Ejercicio N°93",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus x509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/D8NntstDjRuQJfzr5",
    },
    {
      ID: "94",
      Nombre: "Ejercicio N°94",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone6",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Playa El Faro",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "29°54′10″S 71°15′07″O",
      "Link Streetview": "https://goo.gl/maps/m3M3fywKDn3L1duf7",
    },
    {
      ID: "95",
      Nombre: "Ejercicio N°95",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "LG Optimus L3 E400",
      Comuna: "Melipilla",
      "Barrio/Pueblo/Villa": "Pomaire",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°39′00″S 71°10′00″O",
      "Link Streetview": "https://goo.gl/maps/1j3TTZsozsqcjnXa7",
    },
    {
      ID: "96",
      Nombre: "Ejercicio N°96",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook pro 10",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Las Naciones",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°32′7.85″S 70°46′45.69″O",
      "Link Streetview": "https://goo.gl/maps/R34d5doc7rQjQHwV6",
    },
    {
      ID: "97",
      Nombre: "Ejercicio N°97",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung A01",
      Comuna: "Estación Central",
      "Barrio/Pueblo/Villa": "Las Parcelas",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′07″S 70°40′44″O",
      "Link Streetview": "https://goo.gl/maps/gdHFgFjDFP2jKpZQ7",
    },
    {
      ID: "98",
      Nombre: "Ejercicio N°98",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola E6 Plus",
      Comuna: "Macul  ",
      "Barrio/Pueblo/Villa": "Macul con Grecia",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°30′00″S 70°34′00″O",
      "Link Streetview": "https://goo.gl/maps/JedPBkXjFH5LYbsy8",
    },
    {
      ID: "99",
      Nombre: "Ejercicio N°99",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Coquimbo",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "26°08′59″S 70°39′02″O",
      "Link Streetview": "https://goo.gl/maps/qCJ2AJJjQwDea826A",
    },
    {
      ID: "100",
      Nombre: "Ejercicio N°100",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook Pro 10",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Barrio Collao",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′2″S 73°0′46″W",
      "Link Streetview": "https://goo.gl/maps/sKQqBpqdTyWFuycf8",
    },
    {
      ID: "101",
      Nombre: "Ejercicio N°101",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone XR",
      Comuna: "CDMX Ciudad de México",
      "Barrio/Pueblo/Villa": "Valle de México",
      Región: "Colonia de Aragón",
      País: "México",
      Coordenadas: "19°25′10″N 99°08′44″O",
      "Link Streetview": "https://goo.gl/maps/1hZKmgFuUYHDF6aM9",
    },
    {
      ID: "102",
      Nombre: "Ejercicio N°102",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone XR",
      Comuna: "CDMX Ciudad de México",
      "Barrio/Pueblo/Villa": "Valle de México",
      Región: "Colonia de Aragón",
      País: "México",
      Coordenadas: "19°25′10″N 99°08′44″O",
      "Link Streetview": "https://goo.gl/maps/KBa4VhJAr1FpMYDGA",
    },
    {
      ID: "103",
      Nombre: "Ejercicio N°103",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony VAIO",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Villa Frei",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°28′00″S 70°36′00″O",
      "Link Streetview": "https://goo.gl/maps/7FtFmcNm4Lc8PzHi7",
    },
    {
      ID: "104",
      Nombre: "Ejercicio N°104",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony VAIO",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Villa Frei",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°28′00″S 70°36′00″O",
      "Link Streetview": "https://goo.gl/maps/T8VvtFX2opsjyfSW7",
    },
    {
      ID: "105",
      Nombre: "Ejercicio N°105",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo 80NJ",
      Comuna: "La Reina",
      "Barrio/Pueblo/Villa": "Parque Peñalolén",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′S 70°33′O",
      "Link Streetview": "https://goo.gl/maps/qAUbbsM6yNvMxDaW7",
    },
    {
      ID: "106",
      Nombre: "Ejercicio N°106",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 440G3",
      Comuna: "São Paulo",
      "Barrio/Pueblo/Villa": "Villa Buarque",
      Región: "Mesorregión Metropolitana de São Paulo",
      País: "Brasil",
      Coordenadas: "23°33′01″S 46°38′02″O",
      "Link Streetview": "https://goo.gl/maps/zHFvXMw97HVYpR8h7",
    },
    {
      ID: "107",
      Nombre: "Ejercicio N°107",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo 80NJ",
      Comuna: "La Reina",
      "Barrio/Pueblo/Villa": "Parque Peñalolén",
      Región: "Región metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′S 70°33′O",
      "Link Streetview": "https://goo.gl/maps/NQQ1fHb9RW3JSsPR9",
    },
    {
      ID: "108",
      Nombre: "Ejercicio N°108",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 440G3",
      Comuna: "São Paulo",
      "Barrio/Pueblo/Villa": "Villa Buarque",
      Región: "Mesorregión Metropolitana de São Paulo",
      País: "Brasil",
      Coordenadas: "23°33′01″S 46°38′02″O",
      "Link Streetview": "https://goo.gl/maps/jEWHpzgonvPsjZBe9",
    },
    {
      ID: "109",
      Nombre: "Ejercicio N°109",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo 80NJ",
      Comuna: "La Reina",
      "Barrio/Pueblo/Villa": "Parque Peñalolen",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′S 70°33′O",
      "Link Streetview": "https://goo.gl/maps/FW2W5QpppcW2RqnT8",
    },
    {
      ID: "110",
      Nombre: "Ejercicio N°110",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagónica",
      País: "Argentina",
      Coordenadas: "36°09′02″S 70°23′47″O",
      "Link Streetview": "https://goo.gl/maps/2GHzxNppaAPu9zd27",
    },
    {
      ID: "111",
      Nombre: "Ejercicio N°111",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Infinix Smart 4",
      Comuna: "Pereira",
      "Barrio/Pueblo/Villa": "Cañarte",
      Región: "Departamento de Risaralda",
      País: "Colombia",
      Coordenadas: "4°48′51″N 75°41′40″O",
      "Link Streetview": "https://goo.gl/maps/2QXSMBASQYyHijUz6",
    },
    {
      ID: "112",
      Nombre: "Ejercicio N°112",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "XIAOMI Redmi 9",
      Comuna: "Dalcahue",
      "Barrio/Pueblo/Villa": "Isla Grande del Archipielago de Chiloé",
      Región: "Región de los Lagos",
      País: "Chile",
      Coordenadas: "42°22′47″S 73°38′50″O",
      "Link Streetview": "https://goo.gl/maps/gjPj3sPhGkbDKUpy5",
    },
    {
      ID: "113",
      Nombre: "Ejercicio N°113",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Region del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/e39ynNTU361uBGMq7",
    },
    {
      ID: "114",
      Nombre: "Ejercicio N°114",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "XIAOMI Redmi 9",
      Comuna: "Dalcahue",
      "Barrio/Pueblo/Villa": "Isla Grande del Archipielago de Chiloé",
      Región: "Región de los Lagos",
      País: "Chile",
      Coordenadas: "42°22′47″S 73°38′50″O",
      "Link Streetview": "https://goo.gl/maps/Rja8n7QoDYDx3MS2A",
    },
    {
      ID: "115",
      Nombre: "Ejercicio N°115",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Infinix Smart 4",
      Comuna: "Pereira",
      "Barrio/Pueblo/Villa": "Cañarte",
      Región: "Departamento de Risaralda",
      País: "Colombia",
      Coordenadas: "4°48′51″N 75°41′40″O",
      "Link Streetview": "https://goo.gl/maps/DBTFYj24ex7dpyFR8",
    },
    {
      ID: "116",
      Nombre: "Ejercicio N°116",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 11",
      Comuna: "Chillán",
      "Barrio/Pueblo/Villa": "Villa Cuarto Centenario",
      Región: "Región de Ñuble, Conurbación Chillán",
      País: "Chile",
      Coordenadas: "36°36′24″S 72°06′12″O",
      "Link Streetview": "https://goo.gl/maps/z1YkTJRuGX7gMJZN6",
    },
    {
      ID: "117",
      Nombre: "Ejercicio N°117",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Tongoy",
      "Barrio/Pueblo/Villa": "Guanaqueros",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "30°15′27″S 71°29′33″O",
      "Link Streetview": "https://goo.gl/maps/8QUyboYsp7xcnDug8",
    },
    {
      ID: "118",
      Nombre: "Ejercicio N°118",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 11",
      Comuna: "Chillán",
      "Barrio/Pueblo/Villa": "Villa Cuarto Centenario",
      Región: "Región de Ñuble, Conurbación Chillán",
      País: "Chile",
      Coordenadas: "36°36′24″S 72°06′12″O",
      "Link Streetview": "https://goo.gl/maps/MZFtew2oDDUAMiGg7",
    },
    {
      ID: "119",
      Nombre: "Ejercicio N°119",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 5",
      Comuna: "Conchalí",
      "Barrio/Pueblo/Villa": "Metro Conchali",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°21′00″S 70°37′00″O",
      "Link Streetview": "https://goo.gl/maps/F4nbdkE1p3tS1KQD6",
    },
    {
      ID: "120",
      Nombre: "Ejercicio N°120",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′00″S 70°40′00″O",
      "Link Streetview": "https://goo.gl/maps/yRm9o1WuumdydnTh6",
    },
    {
      ID: "121",
      Nombre: "Ejercicio N°121",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Dell Latitude E5420",
      Comuna: "Cobquecura",
      "Barrio/Pueblo/Villa": "Cerro el Calvario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°07′54″S 72°47′30″O",
      "Link Streetview": "https://goo.gl/maps/a7KQkdNEc153awa46",
    },
    {
      ID: "122",
      Nombre: "Ejercicio N°122",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/YTLD3UaNMxDrmxn96",
    },
    {
      ID: "123",
      Nombre: "Ejercicio N°123",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/DYzXeDSsNMGo2KQU8",
    },
    {
      ID: "124",
      Nombre: "Ejercicio N°124",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Dell Latitude E5420",
      Comuna: "Cobquecura",
      "Barrio/Pueblo/Villa": "Cerro el Calvario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°36′24″S 72°06′12″O",
      "Link Streetview": "https://goo.gl/maps/ScBzjVdqRFfVPPEx6",
    },
    {
      ID: "125",
      Nombre: "Ejercicio N°125",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire E1",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Playa las Conchitas",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°24′S 71°42′O",
      "Link Streetview": "https://goo.gl/maps/fUAYinS9Eos1rq977",
    },
    {
      ID: "126",
      Nombre: "Ejercicio N°126",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/WFAL3z8XgU4qyQdX9",
    },
    {
      ID: "127",
      Nombre: "Ejercicio N°127",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola One Fusion",
      Comuna: "Temuco",
      "Barrio/Pueblo/Villa": "Cajón",
      Región: "Región de la Araucanía",
      País: "Chile",
      Coordenadas: "39°07′06″S 72°22′46″O",
      "Link Streetview": "https://goo.gl/maps/uJETMkamQcPVxMGw9",
    },
    {
      ID: "128",
      Nombre: "Ejercicio N°128",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/n1mMSeJUmPBNJLjd7",
    },
    {
      ID: "129",
      Nombre: "Ejercicio N°129",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/wBovHTGZieC6QMZZ7",
    },
    {
      ID: "130",
      Nombre: "Ejercicio N°130",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/nrUmTT8G139h76kx5",
    },
    {
      ID: "131",
      Nombre: "Ejercicio N°131",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/25hrSEUPsVrtfGtt9",
    },
    {
      ID: "132",
      Nombre: "Ejercicio N°132",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Dell Latitude E5420",
      Comuna: "Cobquecura",
      "Barrio/Pueblo/Villa": "Cerro el Calvario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°07′54″S 72°47′30″O",
      "Link Streetview": "https://goo.gl/maps/LiHa71BvqVoiidP29",
    },
    {
      ID: "133",
      Nombre: "Ejercicio N°133",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/K1oSuvC8Nc8nLFTo6",
    },
    {
      ID: "134",
      Nombre: "Ejercicio N°134",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/u8gUUihvTDJzwt2N8",
    },
    {
      ID: "135",
      Nombre: "Ejercicio N°135",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Coquimbo",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "26°08′59″S 70°39′02″O",
      "Link Streetview": "https://goo.gl/maps/Y2feqjxzafF3GUH97",
    },
    {
      ID: "136",
      Nombre: "Ejercicio N°136",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/F6WXAA2owNSMNr9q6",
    },
    {
      ID: "137",
      Nombre: "Ejercicio N°137",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Dell Latitude E5420",
      Comuna: "Cobquecura",
      "Barrio/Pueblo/Villa": "Cerro el Calvario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°07′54″S 72°47′30″O",
      "Link Streetview": "https://goo.gl/maps/bcdREmL4uPYDKiwX8",
    },
    {
      ID: "138",
      Nombre: "Ejercicio N°138",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/J5kF58gxT88uP3yN7",
    },
    {
      ID: "139",
      Nombre: "Ejercicio N°139",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/MJvW5rk6Cc6qxHu57",
    },
    {
      ID: "140",
      Nombre: "Ejercicio N°140",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/8aaiLqAyuPURWKef6",
    },
    {
      ID: "141",
      Nombre: "Ejercicio N°141",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Coquimbo",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "26°08′59″S 70°39′02″O",
      "Link Streetview": "https://goo.gl/maps/eC6Vf5CWxAV567e6A",
    },
    {
      ID: "142",
      Nombre: "Ejercicio N°142",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Arturo Alessandri",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°50′25″S 73°04′07″O",
      "Link Streetview": "https://goo.gl/maps/uDyryPfZYDXnTkdy7",
    },
    {
      ID: "143",
      Nombre: "Ejercicio N°143",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire E1",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Playa las Conchitas ",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°24′S 71°42′O",
      "Link Streetview": "https://goo.gl/maps/Tu1K5cxbXupDqkqH9",
    },
    {
      ID: "144",
      Nombre: "Ejercicio N°144",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/pVTPFiwRsAeM4Zmd6",
    },
    {
      ID: "145",
      Nombre: "Ejercicio N°145",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/sUXNwv616HGVUrgs7",
    },
    {
      ID: "146",
      Nombre: "Ejercicio N°146",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/f7PncZLV5u8qHix37",
    },
    {
      ID: "147",
      Nombre: "Ejercicio N°147",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/vnsR2bhLNwSMQihh8",
    },
    {
      ID: "148",
      Nombre: "Ejercicio N°148",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iMac",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Tres poniente",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°31′00″S 70°46′00″O",
      "Link Streetview": "https://goo.gl/maps/qa7WhuQVwSwDjahY9",
    },
    {
      ID: "149",
      Nombre: "Ejercicio N°149",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/o91wn5xxfNT5NKVz8",
    },
    {
      ID: "150",
      Nombre: "Ejercicio N°150",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Dell Latitude E5420",
      Comuna: "Cobquecura",
      "Barrio/Pueblo/Villa": "Cerro el Calvario",
      Región: "Región de Ñuble",
      País: "Chile",
      Coordenadas: "36°07′54″S 72°47′30″O",
      "Link Streetview": "https://goo.gl/maps/xx1dBi6P8AYUnCSFA",
    },
    {
      ID: "151",
      Nombre: "Ejercicio N°151",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/V34bmLM67KsNimfn7",
    },
    {
      ID: "152",
      Nombre: "Ejercicio N°152",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/vfmgbFZhFEMcajCc6",
    },
    {
      ID: "153",
      Nombre: "Ejercicio N°153",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Samsung Galaxy S5",
      Comuna: "Los Ángeles",
      "Barrio/Pueblo/Villa": "Clara de Godoy",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "37°28′15″S 72°21′06″O",
      "Link Streetview": "https://goo.gl/maps/RuhheE645NW4BPpq9",
    },
    {
      ID: "154",
      Nombre: "Ejercicio N°154",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Iphone 8",
      Comuna: "Coquimbo",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "26°08′59″S 70°39′02″O",
      "Link Streetview": "https://goo.gl/maps/15GTDrbC2J1FErYz7",
    },
    {
      ID: "155",
      Nombre: "Ejercicio N°155",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/LjoDzqScSUFW3JJG6",
    },
    {
      ID: "156",
      Nombre: "Ejercicio N°156",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire E1",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Playa las Conchitas",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°24′S 71°42′O",
      "Link Streetview": "https://goo.gl/maps/HWhYNKT4jqYhEZji6",
    },
    {
      ID: "157",
      Nombre: "Ejercicio N°157",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "San Pedro de la Paz",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/pFiTcdsEGUoh7NzE9",
    },
    {
      ID: "158",
      Nombre: "Ejercicio N°158",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/WBTpQK4BTAByAGNi8",
    },
    {
      ID: "159",
      Nombre: "Ejercicio N°159",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/WYSiafhB1SrsF5Xr7",
    },
    {
      ID: "160",
      Nombre: "Ejercicio N°160",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/6hw29sBZaieMqZGTA",
    },
    {
      ID: "161",
      Nombre: "Ejercicio N°161",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Region del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/YX5ZTYv23vMuNsoi8",
    },
    {
      ID: "162",
      Nombre: "Ejercicio N°162",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "HUAWEI MateBook X Pro",
      Comuna: "Buenos Aires",
      "Barrio/Pueblo/Villa": "Capital federal",
      Región: "Ciudad autónoma de Buenos Aires",
      País: "Argentina",
      Coordenadas: "34°35′59″S 58°22′55″O",
      "Link Streetview": "https://goo.gl/maps/5yT3ShWHABxjLqHt7",
    },
    {
      ID: "163",
      Nombre: "Ejercicio N°163",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/YG814xX5NFfJu4tU7",
    },
    {
      ID: "164",
      Nombre: "Ejercicio N°164",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/KDk3EHKz3sPuHA52A",
    },
    {
      ID: "165",
      Nombre: "Ejercicio N°165",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/o94Qj3Z3rpab4ahv9",
    },
    {
      ID: "166",
      Nombre: "Ejercicio N°166",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/87oFWnbarX5y5KFXA",
    },
    {
      ID: "167",
      Nombre: "Ejercicio N°167",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/QfL9RwGNG3qukvHk8",
    },
    {
      ID: "168",
      Nombre: "Ejercicio N°168",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire E1",
      Comuna: "El Quisco",
      "Barrio/Pueblo/Villa": "Playa las Conchitas ",
      Región: "Litoral de los poetas",
      País: "Chile",
      Coordenadas: "33°24′S 71°42′O",
      "Link Streetview": "https://goo.gl/maps/CmLZnBJs2acJVCHQ9",
    },
    {
      ID: "169",
      Nombre: "Ejercicio N°169",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/M1RwkcJ1H9JNMis29",
    },
    {
      ID: "170",
      Nombre: "Ejercicio N°170",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Region del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/ddYzxa6cGAZCfdDR7",
    },
    {
      ID: "171",
      Nombre: "Ejercicio N°171",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/kdvqJMvdyFuCrzRL9",
    },
    {
      ID: "172",
      Nombre: "Ejercicio N°172",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/juRk6zj1xXifrHzZ7",
    },
    {
      ID: "173",
      Nombre: "Ejercicio N°173",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Region del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/4duT81mQGaDapGWq6",
    },
    {
      ID: "174",
      Nombre: "Ejercicio N°174",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/ABVx8G9CfXxqFMqg8",
    },
    {
      ID: "175",
      Nombre: "Ejercicio N°175",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/EKoZ5Uf77xrTgHYh8",
    },
    {
      ID: "176",
      Nombre: "Ejercicio N°176",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Macbook air",
      Comuna: "Las Condes",
      "Barrio/Pueblo/Villa": "Escuela Militar",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°24′51″S 70°35′05″O",
      "Link Streetview": "https://goo.gl/maps/Bxzw8HCSN4EvvdZA6",
    },
    {
      ID: "177",
      Nombre: "Ejercicio N°177",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/NDsCyxhshSWWx6PV7",
    },
    {
      ID: "178",
      Nombre: "Ejercicio N°178",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Motorola One Fusion",
      Comuna: "Temuco",
      "Barrio/Pueblo/Villa": "Cajón",
      Región: "Región de la Araucanía",
      País: "Chile",
      Coordenadas: "39°07′06″S 72°22′46″O",
      "Link Streetview": "https://goo.gl/maps/TtEsqicLujaNgRVU8",
    },
    {
      ID: "179",
      Nombre: "Ejercicio N°179",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Barrio Bellas Artes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′12″S 70°38′40″O",
      "Link Streetview": "https://goo.gl/maps/WBmwL3H5XBb1aFwB8",
    },
    {
      ID: "180",
      Nombre: "Ejercicio N°180",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/Uj6eK5pnScioDoK39",
    },
    {
      ID: "181",
      Nombre: "Ejercicio N°181",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iMac",
      Comuna: "Maipú",
      "Barrio/Pueblo/Villa": "Villa Los héroes",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°32′7.85″S 70°46′45.69″O",
      "Link Streetview": "https://goo.gl/maps/ZSqt6XMaLv71Jtxo9",
    },
    {
      ID: "182",
      Nombre: "Ejercicio N°182",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Tongoy",
      "Barrio/Pueblo/Villa": "Guanaqueros",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "30°11′50″S 71°25′20″O",
      "Link Streetview": "https://goo.gl/maps/7sDTzxDbUQnNJBbx5",
    },
    {
      ID: "183",
      Nombre: "Ejercicio N°183",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/TXwheWSSGC5gDYe19",
    },
    {
      ID: "184",
      Nombre: "Ejercicio N°184",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagónica",
      País: "Argentina",
      Coordenadas: "41°09′S 71°18′O",
      "Link Streetview": "https://goo.gl/maps/qTVH2KSeH3sngCws5",
    },
    {
      ID: "185",
      Nombre: "Ejercicio N°185",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavillion 14 sleekbook",
      Comuna: "Bariloche",
      "Barrio/Pueblo/Villa": "Patagonia Argentina",
      Región: "Región Patagónica",
      País: "Argentina",
      Coordenadas: "41°09′S 71°18′O",
      "Link Streetview": "https://goo.gl/maps/YXPdK1CxfK2uTLvKA",
    },
    {
      ID: "186",
      Nombre: "Ejercicio N°186",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp pavilion Gaming15",
      Comuna: "Santiago Centro",
      "Barrio/Pueblo/Villa": "Torres Remodelación San Borja",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°26′27″S 70°38′16″O",
      "Link Streetview": "https://goo.gl/maps/Pn7ao3BqvEiMu7tf7",
    },
    {
      ID: "187",
      Nombre: "Ejercicio N°187",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Tongoy",
      "Barrio/Pueblo/Villa": "Guanaqueros",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "30°11′50″S 71°25′20″O",
      "Link Streetview": "https://goo.gl/maps/Mf1DUf6YaTGeptL57",
    },
    {
      ID: "188",
      Nombre: "Ejercicio N°188",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus X509JA",
      Comuna: "San Bernardo",
      "Barrio/Pueblo/Villa": "Plaza La Lata",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°36′00″S 70°43′00″O",
      "Link Streetview": "https://goo.gl/maps/7N2P9QwkqbZ63y9n6",
    },
    {
      ID: "189",
      Nombre: "Ejercicio N°189",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 8",
      Comuna: "La Serena",
      "Barrio/Pueblo/Villa": "Pan de Azúcar",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "26°08′59″S 70°39′02″O",
      "Link Streetview": "https://goo.gl/maps/1hg687ggGGZKrPfYA",
    },
    {
      ID: "190",
      Nombre: "Ejercicio N°190",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Lenovo 80NJ",
      Comuna: "La Reina",
      "Barrio/Pueblo/Villa": "Parque Peñalolén",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′S 70°33′O",
      "Link Streetview": "https://goo.gl/maps/zj6HbTUGBLaq6QXX6",
    },
    {
      ID: "191",
      Nombre: "Ejercicio N°191",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 6",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Cerro La Virgen",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′38″S 73°03′01″O ",
      "Link Streetview": "https://goo.gl/maps/4wYu4VkGS6f1zZsW6",
    },
    {
      ID: "192",
      Nombre: "Ejercicio N°192",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony Vaio PCG 31311u",
      Comuna: "Providencia",
      "Barrio/Pueblo/Villa": "Tobalaba",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°25′05″S 70°36′06″O",
      "Link Streetview": "https://goo.gl/maps/EKGtXQ7TwXePqzk7A",
    },
    {
      ID: "193",
      Nombre: "Ejercicio N°193",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Sony VAIO",
      Comuna: "Ñuñoa",
      "Barrio/Pueblo/Villa": "Villa Frei",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°28′00″S 70°36′00″O",
      "Link Streetview": "https://goo.gl/maps/KKC72znBX8Yixs4Y8",
    },
    {
      ID: "194",
      Nombre: "Ejercicio N°194",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone X",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Parque Ecuador",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°49′58″S 73°02′50″O",
      "Link Streetview": "https://goo.gl/maps/SciFXgUfA4Gcc4kk9",
    },
    {
      ID: "195",
      Nombre: "Ejercicio N°195",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "XIAOMI Redmi 9",
      Comuna: "Dalcahue",
      "Barrio/Pueblo/Villa": "Isla Grande del Archipielago de Chiloé",
      Región: "Región de Los lagos",
      País: "Chile",
      Coordenadas: "42°22′47″S 73°38′50″O",
      "Link Streetview": "https://goo.gl/maps/JzESXKYjRs9P4fx7A",
    },
    {
      ID: "196",
      Nombre: "Ejercicio N°196",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Asus ROG Zephyrus G14",
      Comuna: "Bogotá",
      "Barrio/Pueblo/Villa": "Kennedy",
      Región: "Distrito Capital de Bogotá",
      País: "Colombia",
      Coordenadas: "4°38′37″N 74°09′12″O",
      "Link Streetview": "https://goo.gl/maps/CfEG9j7gB5zdUdJ38",
    },
    {
      ID: "197",
      Nombre: "Ejercicio N°197",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "iPhone 10",
      Comuna: "Estación Central",
      "Barrio/Pueblo/Villa": "Las Rejas",
      Región: "Región Metropolitana de Santiago",
      País: "Chile",
      Coordenadas: "33°27′07″S 70°40′44″O",
      "Link Streetview": "https://goo.gl/maps/HAr98EVZQGnXYwZ97",
    },
    {
      ID: "198",
      Nombre: "Ejercicio N°198",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Hp 245 G7",
      Comuna: "Tongoy",
      "Barrio/Pueblo/Villa": "Guanaqueros",
      Región: "Región de Coquimbo",
      País: "Chile",
      Coordenadas: "30°11′50″S 71°25′20″O",
      "Link Streetview": "https://goo.gl/maps/RMUkherSMM58sfQ8A",
    },
    {
      ID: "199",
      Nombre: "Ejercicio N°199",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Acer Aspire e15",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Hualpén",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°47′00″S 73°05′00″O",
      "Link Streetview": "https://goo.gl/maps/KQk6aTgssu6qHWdN7",
    },
    {
      ID: "200",
      Nombre: "Ejercicio N°200",
      Técnica: "Fotografía online por webcam",
      Fecha: "Pandemia 2020",
      Dispositivo: "Packard bell MS 2397",
      Comuna: "Concepción ",
      "Barrio/Pueblo/Villa": "Lomas coloradas",
      Región: "Región del Bío Bío",
      País: "Chile",
      Coordenadas: "36°53′03″S 73°08′05″O",
      "Link Streetview": "https://goo.gl/maps/XqmsJoYykZpmdgHWA",
    },
  ],
};
